const fs = require('hexo-fs');
const path = require('path');
// const log = require('hexo-log')({
//   debug: false,
//   silent: false
// });
// [Bug] ERROR Plugin load failed: hexo-live2d-rem; TypeError: require(...) is not a function

const options = {
  options: [
    { name: '-i, --init', desc: 'Initialize live2d-rem' },
    { name: '-r, --remove', desc: 'Remove live2d-rem' }
  ]
};

hexo.extend.console.register('live2d-rem', 'Generate JiJi\'s wife -- Rem', options, function (args) {
  if (!this?.config?.live2d_rem?.enable) {
    // log.info('Please enable live2d-rem in _config.yml first!');
    return;
  }
  if (args.i) {
    if (!fs.existsSync(path.join(this.source_dir, '/live2d/message.json'))) {
      fs.copyDir(path.join(__dirname, 'live2d'), path.join(this.source_dir, '/live2d/'));
      // log.info('Initialization succeeded');
    }
  } else if (args.r) {
    if (fs.existsSync(path.join(this.source_dir, '/live2d/message.json'))) {
      fs.rmdir(path.join(this.source_dir, '/live2d'));
      // log.info('The live2d-rem has been removed');
    } else {
      // log.info('No live2d-rem to remove');
    }
  }
});

hexo.extend.filter.register('after_render:html', function (html) {
  if (!this?.config?.live2d_rem?.enable) {
    return;
  }
  const addHtml = `
  <!-- Generated by hexo-live2d-rem -->
  <div id="rembox">
    <div id="landlord">
      <div class="message" style="opacity:0"></div>
      <canvas id="live2d" width="500" height="560" class="live2d"></canvas>
      <input name="live_talk" id="live_talk" value="1" type="hidden" />
    </div>
    <div id="open_live2d"></div>
  </div>
  <script src="/live2d/js/jquery.min.js"></script>
  <link rel="stylesheet" href="/live2d/css/live2d.css" />
  <script type="text/javascript" src="/live2d/js/live2d.js"></script>
  <script type="text/javascript" src="/live2d/js/message.js"></script>
  <!-- https://github.com/mmdjiji/hexo-live2d-rem -->`;
  let result = html;
  if ((/([\n\r\s\t]*<\/head>)/i).test(html)) {
    const lastIndex = html.lastIndexOf('</head>');
    result = `${html.substring(0, lastIndex)}${addHtml}${html.substring(lastIndex, html.length)}`;
  }
  return result;
});
